import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

import Block from "../Block";
import Badge from "../../Badge";

import { BlockTitle, CTALink, primaryColour, darkGreen, darkRed } from "../../../styles/component-stylings";
import { ArrowRight, ExternalLink } from "react-feather";
import { device_query } from "../../../helpers/device_breakpoints";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ColumnBlockContainer = styled.div`
    width: 100%;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

const HighlightsContainerAnchor = styled.a`
    width: 100%;
    margin: 1rem 0;
    border: 1px solid rgba(0, 0, 0, 0.125);
    color: black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: filter 150ms ease-in, box-shadow 150ms ease-in;

    text-decoration: none;

    &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    }

    &:visited {
        color: inherit;
    }

    @media ${device_query.laptop} {
        width: calc((100% / 3) - 3rem);
        margin: 1rem;
    }
`;

const HighlightsContainerLink = styled((props) => <Link {...props} />)`
    width: 100%;
    margin: 1rem 0;
    border: 1px solid rgba(0, 0, 0, 0.125);
    color: black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: filter 150ms ease-in, box-shadow 150ms ease-in;

    text-decoration: none;

    &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    }

    &:visited {
        color: inherit;
    }

    @media ${device_query.laptop} {
        width: calc((100% / 3) - 3rem);
        margin: 1rem;
    }
`;

const HighlightsImageStyle = {
    height: "300px",
    width: "100%",
};

const HighlightsTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    background-color: white;

    padding: 1rem 0;
    border-top: solid 1px #3c3c42;
`;

const HighlightsFakeLink = styled.p`
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0.75rem 0;
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: underline;
    }
`;

const Highlights = () => {
    return (
        <Block title='Highlights' margin padding minHeight noTopMargin backgroundColour='#ebe8e1'>
            <Container>
                <BlockTitle>Highlights</BlockTitle>
                <ColumnBlockContainer>
                    <HighlightsContainerAnchor href='https://online.afp.gov.au/ironside' target='_blank' rel='external'>
                        <StaticImage
                            style={HighlightsImageStyle}
                            objectFit='cover'
                            src='../../../images/ironside.png'
                            alt='Operation Ironside'
                            loading='lazy'
                        />
                        <HighlightsTextContainer>
                            <Badge backgroundColour={darkRed}>Operation</Badge>
                            <HighlightsFakeLink>
                                Operation Ironside <ExternalLink size='1.25rem' style={{ marginLeft: "0.25rem" }} />
                            </HighlightsFakeLink>
                        </HighlightsTextContainer>
                    </HighlightsContainerAnchor>
                    <HighlightsContainerLink to='the-work/highlights/#hamilton'>
                        <StaticImage
                            style={HighlightsImageStyle}
                            objectFit='cover'
                            objectPosition={"center"}
                            src='../../../images/hamilton-map.jpg'
                            alt='Hamilton Map'
                            placeholder='blurred'
                            loading='lazy'
                        />
                        <HighlightsTextContainer>
                            <Badge backgroundColour={primaryColour}>Analytics</Badge>
                            <HighlightsFakeLink>Hamilton</HighlightsFakeLink>
                        </HighlightsTextContainer>
                    </HighlightsContainerLink>
                    <HighlightsContainerLink to='the-work/highlights/#wifi-charger'>
                        <StaticImage
                            style={HighlightsImageStyle}
                            objectFit='cover'
                            objectPosition={"60% bottom"}
                            src='../../../images/device-1.jpg'
                            alt='Circuit Board'
                            placeholder='blurred'
                            loading='lazy'
                        />
                        <HighlightsTextContainer>
                            <Badge backgroundColour={darkGreen}>Hardware</Badge>
                            <HighlightsFakeLink>WiFi Charger</HighlightsFakeLink>
                        </HighlightsTextContainer>
                    </HighlightsContainerLink>
                </ColumnBlockContainer>
                <CTALink to='the-work/highlights' rel='next'>
                    <span>View highlights</span>
                    <ArrowRight />
                </CTALink>
            </Container>
        </Block>
    );
};
export default Highlights;
