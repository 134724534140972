import * as React from "react";
import styled from "styled-components";
import Block from "./Block";

import { device_query } from "../../helpers/device_breakpoints";

const TwoColumnBlock = ({ children, margin, padding, backgroundColour, isFirstBlock, minHeight, reverse }) => {
    const ColumnBlockContainer = styled.div`
        min-height: 25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        & > div {
            width: 100%;
        }

        @media ${device_query.laptop} {
            & > div:nth-child(1) {
                width: ${reverse ? "calc((100% / 3 * 2) - 1rem)" : "calc((100% / 3) - 1rem)"};
                padding: 0 1rem;
            }
            & > div:nth-child(2) {
                width: ${reverse ? "calc((100% / 3) - 1rem)" : "calc((100% / 3 * 2) - 1rem)"};
            }
        }
    `;

    return (
        <Block margin={margin} padding={padding} backgroundColour={backgroundColour} isFirstBlock={isFirstBlock} minHeight={minHeight}>
            <ColumnBlockContainer>{children}</ColumnBlockContainer>
        </Block>
    );
};

export default TwoColumnBlock;
