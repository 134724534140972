import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import PageLayout from "../components/Layout/PageLayout";
import TwoColumnBlock from "../components/Layout/2ColumnBlock";
import Hero from "../components/Layout/Main/Hero";
import HighlightsBlock from "../components/Layout/Main/HighlightsBlock";
import OurPeopleSlider from "../components/Layout/Main/OurPeopleSlider";

import { CTALink, BlockTitle, InlineExternalLink } from "../styles/component-stylings";
import { ArrowRight } from "react-feather";

import HERO_IMG from "../images/casual-people.jpg";
import CareerHero from "../components/Layout/Main/CareerHero";

const PAGE_TITLE = "Home";

const imageContainerStyle = {
    height: "25rem",
    width: "100%",
};

const IndexPage = () => {
    return (
        <PageLayout pageTitle={PAGE_TITLE}>
            <Helmet>
                <meta name='description' content='Main Page for Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <Hero
                img_url={HERO_IMG}
                title='Digital Surveillance Collection'
                subtitle='Creating technical solutions for operational problems'
                linkhref='/careers'
            />

            {/* who we are */}
            <TwoColumnBlock margin padding minHeight>
                <div>
                    <BlockTitle>Who we are</BlockTitle>
                    <p>
                        Digital Surveillance Collection (DSC) is a specialist technical branch in the{" "}
                        <InlineExternalLink href='https://www.afp.gov.au' target='_blank' rel='external'>
                            Australian Federal Police
                        </InlineExternalLink>{" "}
                        that collaborates and builds strong relationships with domestic law enforcement and national security agencies and international
                        counterparts.
                    </p>
                    <p>DSC is at the forefront of technology for law enforcement and national security.</p>
                    <p>We assist in criminal investigations and data management by utilising state-of-the-art technologies and solutions.</p>
                    <CTALink to='/who-we-are' rel='next'>
                        <span>Get to know us</span>
                        <ArrowRight />
                    </CTALink>
                </div>
                <div>
                    <StaticImage
                        src='../images/collage.jpg'
                        style={imageContainerStyle}
                        placeholder='blurred'
                        layout='fixed'
                        alt='Collage of assorted technologies'
                    />
                </div>
            </TwoColumnBlock>

            {/* the work */}
            <TwoColumnBlock margin padding minHeight backgroundColour='#ebe8e1' reverse>
                <div>
                    <StaticImage src='../images/technology.jpg' style={imageContainerStyle} placeholder='blurred' layout='fixed' alt='Technology' />
                </div>
                <div>
                    <BlockTitle>The work</BlockTitle>
                    <p>
                        DSC's core function is to support criminal investigations via multi-disciplinary teams, including data scientists, software developers,
                        reverse engineers and hardware engineers, amongst other technical disciplines.
                    </p>
                    <p>
                        We are consistently recognised by our state and international partners as leaders in developing technical solutions for complex
                        problems.
                    </p>
                    <CTALink to='/the-work' rel='next'>
                        <span>Explore what we do</span>
                        <ArrowRight />
                    </CTALink>
                </div>
            </TwoColumnBlock>

            <HighlightsBlock />

            {/* careers */}
            <TwoColumnBlock margin padding minHeight>
                <div>
                    <BlockTitle>Careers</BlockTitle>
                    <p>
                        DSC is made up of talented and creative technical problem solvers who are motivated by complex challenges, innovation and making a
                        difference in Australia's national security and community safety interests.
                    </p>
                    <p>
                        We pride ourselves in having one of the most ground-breaking and relaxed work cultures in all of public service, but with private
                        industry benefits.
                    </p>
                    <CTALink to='/careers' rel='next'>
                        <span>Join our team</span>
                        <ArrowRight />
                    </CTALink>
                </div>
                <div>
                    <StaticImage
                        src='../images/casual-meeting.jpg'
                        style={imageContainerStyle}
                        placeholder='blurred'
                        layout='fixed'
                        alt='Casual Meeting Outside'
                    />
                </div>
            </TwoColumnBlock>

            <TwoColumnBlock margin padding minHeight>
                <div>
            <BlockTitle>DSC external partners and engagment</BlockTitle>
            <p>
                DSC is a recognised leader in developing innovative technologies within the law enforcement space.
                 We are passionate about our people and actively engage with tthe public to share our passion.
            </p>
            <p>
                DSC is a Diamond Sponser of the University of New South Wales Women in Technology (UNSWWIT).
                 You may also see us at careers days, leadership conferences, and presenting at tech conferences.

            </p>
            </div>
            <div>
                    <StaticImage
                        src='../images/WIT-logo-black.png'
                        style={imageContainerStyle}
                        placeholder='blurred'
                        layout='fixed'
                        alt='WIT logo'
                    />
                </div>
            </TwoColumnBlock>
            <OurPeopleSlider />
            <CareerHero />
        </PageLayout>
    );
};

export default IndexPage;
