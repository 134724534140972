import * as React from "react";

import Block from "../Block";

import { BlockTitle, CTALink, primaryColour } from "../../../styles/component-stylings";
import { ArrowRight } from "react-feather";

const CareerHero = () => {
    return (
        <Block margin padding backgroundColour={primaryColour}>
            <BlockTitle $colour='white'>Come and build the future you want</BlockTitle>

            <p style={{ color: "white" }}>
                We want you to provide your skills and expertise, and reap all the benefits of a flexible and meaningful work culture. We are one of the AFP's
                fastest-growing areas, so why don't you come and experience variety, a collaborative and inclusive culture, unique challenges and the reward of
                protecting Australia and Australian interests from serious crime?
            </p>

            <CTALink $invert $borderColour='white' to='/careers' rel='next'>
                <span>Learn more about careers</span>
                <ArrowRight />
            </CTALink>
        </Block>
    );
};

export default CareerHero;
