import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";

import { ArrowRight, ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import Block from "../Block";

import { primaryColour, BlockTitleContainer, CTALink, BlockTitle } from "../../../styles/component-stylings";
import { device_query } from "../../../helpers/device_breakpoints";

// avatar svgs from avataaars
import female_avatar_1 from "../../../images/female-avatar-1.svg";
import male_avatar_1 from "../../../images/male-avatar-1.svg";
import female_avatar_2 from "../../../images/female-avatar-2.svg";
import male_avatar_2 from "../../../images/male-avatar-2.svg";

// background svg from app.haikei.app
import svg_background_1 from "../../../images/layered-waves-1.svg";
import svg_background_2 from "../../../images/layered-waves-2.svg";
import svg_background_3 from "../../../images/layered-waves-3.svg";
import svg_background_4 from "../../../images/layered-waves-4.svg";

const quotesObject = [
    {
        avatar: female_avatar_1,
        description: "Female Avatar 1",
        url: "/careers/our-people/#A",
        alias: "Ailie",
        role: "Technican",
        quote: "Having worked in other areas in the AFP, I can say without a doubt that DSC has the best working culture.",
        background: svg_background_1,
    },
    {
        avatar: male_avatar_1,
        description: "Male Avatar 1",
        url: "/careers/our-people/#B",
        alias: "Brandon",
        role: "Software Engineer",
        quote: "There's not a day on the job that I don't learn something new, we're always pushing limits and boundaries on what can be done.",
        background: svg_background_2,
    },
    {
        avatar: female_avatar_2,
        description: "Female Avatar 2",
        url: "/careers/our-people/#I",
        alias: "Isobel",
        role: "Software Developer",
        quote: "The AFP provides the opportunity to assist with and support work that has direct and visible positive outcomes.",
        background: svg_background_3,
    },
    {
        avatar: male_avatar_2,
        description: "Male Avatar 2",
        url: "/careers/our-people/#D",
        alias: "David",
        role: "Mechatronics Engineer",
        quote: "DSC really has the mix of all elements to successfully innovate and catch bad guys.",
        background: svg_background_4,
    },
];

const QuoteContainer = styled.div`
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
`;

const QuoteLink = styled.a`
    text-decoration: none;
    color: black;
    font-size: 1rem;

    &:hover {
        text-decoration: underline;
    }

    @media ${device_query.laptop} {
        font-size: inherit;
    }
`;

const QuoteText = styled(QuoteLink)`
    margin: 1rem;
`;

const QuotePerson = styled(QuoteLink)`
    font-size: 0.9rem;
    font-style: italic;
    margin: 0;
`;

const AvatarImg = styled.img`
    height: 50%;

    @media ${device_query.laptop} {
        max-height: 60%;
    }
`;

const d = styled.button`
    background-color: transparent;
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.25;

    transition: all 150ms ease;

    & > svg {
        width: 100%;
        height: 100%;
    }

    &:hover {
        background-color: ${primaryColour};
        color: white;
        opacity: 1;
    }

    &:focus-visible {
        outline: 1px auto;
    }
`;

const StepBackArrow = styled(d)`
    position: absolute;
    top: 50%;
    left: 0;
    margin: 0;
    padding: 0;
`;

const StepForwardArrow = styled(d)`
    position: absolute;
    top: 50%;
    right: 0;
    margin: 0;
    padding: 0;
`;

const CTALinkContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const OurPeopleSlider = () => {
    const [index, setIndex] = useState(0);
    const [selectedAvatar, setSelectedAvatar] = useState(quotesObject[index]);

    const PeopleContainer = styled.div`
        height: 25rem;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-image: url(${quotesObject[index].background});
        background-size: cover;
        margin-bottom: 2rem;
    `;

    useEffect(() => {
        setSelectedAvatar(quotesObject[index]);
    }, [index]);

    return (
        <Block margin padding>
            <BlockTitleContainer>
                <BlockTitle>Meet our people</BlockTitle>
            </BlockTitleContainer>
            <PeopleContainer>
                <QuoteContainer>
                    <QuoteText href={selectedAvatar.url}>"{selectedAvatar.quote}"</QuoteText>
                    <QuotePerson href={selectedAvatar.url}>
                        <b>{selectedAvatar.alias}</b>, {selectedAvatar.role}
                    </QuotePerson>
                </QuoteContainer>
                <AvatarImg src={selectedAvatar.avatar} alt={selectedAvatar.description} loading='lazy' />
                <StepBackArrow
                    aria-label='Back Button for People Slider'
                    onClick={() => {
                        setIndex((oldState) => (oldState > 0 ? (oldState -= 1) : quotesObject.length - 1));
                    }}
                >
                    <ArrowLeftCircle />
                </StepBackArrow>
                <StepForwardArrow
                    aria-label='Forward Button for People Slider'
                    onClick={() => {
                        setIndex((oldState) => (oldState += 1) % quotesObject.length);
                    }}
                >
                    <ArrowRightCircle />
                </StepForwardArrow>
            </PeopleContainer>
            <CTALinkContainer>
                <CTALink to='/careers/our-people' rel='next'>
                    <span>Meet us</span>
                    <ArrowRight />
                </CTALink>
            </CTALinkContainer>
        </Block>
    );
};

export default OurPeopleSlider;
