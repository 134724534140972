import * as React from "react";
import styled from "styled-components";

import { navBarHeight } from "../../styles/component-stylings";
import { device_query, device_size } from "../../helpers/device_breakpoints";

const Block = ({ children, margin, padding, backgroundColour, isFirstBlock, minHeight, noTopMargin }) => {
    const Section = styled.section`
        width: 100%;
        ${isFirstBlock && `margin-top: ${navBarHeight};`}
        ${backgroundColour && `background-color: ${backgroundColour};`}
    `;

    const Container = styled.div`
        ${minHeight && "min-height: 25rem;"}
        padding: ${padding ? "0" : "0 2rem"}; // reverse padding to align with afp logo
        margin: ${margin ? "5rem 2rem" : "0"};
        ${noTopMargin && "margin-top: 0"};

        @media ${device_query.laptop} {
            padding: ${padding ? "0 2rem" : "0"};
            margin: ${margin ? `5rem calc((100% - ${device_size.laptop}) / 2)` : "0"};
            ${noTopMargin && "margin-top: 0"};
        }
    `;

    return (
        <Section>
            <Container>{children}</Container>
        </Section>
    );
};

export default Block;
