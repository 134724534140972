import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";

import { CTALink, navBarHeight } from "../../../styles/component-stylings";
import { device_query } from "../../../helpers/device_breakpoints";

import Scrambler from "scrambling-letters/dist/scrambler.esm.js";

const HeroTextContainer = styled.div`
    position: absolute;
    width: 100%;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const HeroHeading = styled.h1`
    font-weight: 800;
    font-size: 3rem;
    color: white;

    @media ${device_query.laptop} {
        font-size: 5rem;
    }
`;

const HeroSubtext = styled.h2`
    font-weight: 500;
    color: white;
    font-size: 1rem;

    @media ${device_query.laptop} {
        font-size: 2rem;
    }
`;

const DiscoverLink = styled(CTALink)`
    font-size: 1rem;
`;

const Hero = ({ img_url, heroHeight, title, subtitle, linkhref, linkChildren }) => {
    const HeroWrapper = styled.div`
        height: ${heroHeight || "640px"};
        width: 100%;
        text-align: center;
        position: relative;
        margin-top: ${navBarHeight};

        &::after {
            content: "";
            background-image: url(${img_url});
            background-size: cover;
            background-position: center;
            filter: brightness(30%);
            opacity: 0.8;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;
        }
    `;

    useEffect(() => {
        // initiating scramble on mount
        Scrambler({
            target: ["#hero-title", "#hero-subtitle"],
            random: [750, 1500],
            speed: 75,
        });
    }, []);

    return (
        <HeroWrapper>
            <HeroTextContainer>
                {title && <HeroHeading id='hero-title'>{title}</HeroHeading>}
                {subtitle && <HeroSubtext id='hero-subtitle'>{subtitle}</HeroSubtext>}
                {linkChildren && <DiscoverLink to={linkhref}>{linkChildren}</DiscoverLink>}
            </HeroTextContainer>
        </HeroWrapper>
    );
};

export default Hero;
